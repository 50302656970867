const Rellax = require('rellax');
const svgMap = require('svgmap');
import Vimeo from '@vimeo/player';
import { svgMapStatPerCountryPush } from './ratesDataPush'
import { svgMapStatPerCountryOnclick } from './ratesDataOnclick'
import { svgMapStatPerCountryIPP } from './ratesDataIPP'

// get environment-related url
let url = '';
if (/pushok.me/.test(window.location.origin)) {
    url = 'https://api.pushok.me';
} else if (/rollerads.com/.test(window.location.host)) {
    url = 'https://api.rollerads.com';
} else {
    url = 'http://localhost:8888';
}

// menu toggler
const menu = document.querySelector('.header__menu');
const header = document.querySelector('.header');
const logo = document.querySelector('.header__logo');
const nav = document.querySelector('.header__nav');

menu.addEventListener('click', () => {
    menu.classList.toggle('header__menu--hamburger');
    header.classList.toggle('header--hamburger');
    logo.classList.toggle('header__logo--hamburger');
    nav.classList.toggle('header__nav--hamburger');
});

// fixed header
document.addEventListener('scroll', () => {
    if (pageYOffset >= 1) {
        header.classList.add('header--fixed');
        logo.classList.add('header__logo--fixed');
    } else {
        header.classList.remove('header--fixed');
        logo.classList.remove('header__logo--fixed');
    }
});

// phone with parallax effect
const rellaxElementPush = document.querySelectorAll('.about-push__phone--rellax');

if (typeof rellaxElementPush !== 'undefined' && window.innerWidth >= 1280) {

    for (let i = 0; i < rellaxElementPush.length; i++) {
        new Rellax(rellaxElementPush[i], {
            speed: -5,
            center: true,
        });
    }

}

// Card animation on scroll (statistics section)
let scroll = window.requestAnimationFrame;
let elementsToShow = document.querySelectorAll('.statistics__cards');

function loop() {
    Array.prototype.forEach.call(elementsToShow, function(element){
        if (isElementInViewport(element)) {
            element.classList.add('statistics__cards--visible');
        } else {
            element.classList.remove('statistics__cards--visible');
        }
    });

    scroll(loop);
}

loop();

function isElementInViewport(el) {
    let rect = el.getBoundingClientRect();
    return (
        (rect.top <= 0 && rect.bottom >= 0)
        ||
        (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight))
        ||
        (rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
    );
}

// tabs toggler (key features & formats section)
let tabs = document.querySelectorAll('.tabs__tab');

if (tabs) {
    for (let i = 0; i < tabs.length; i++) {
        tabs[i].addEventListener('click', () => {
            switchTab(tabs[i]);
        });
    }
}

function switchTab(tab) {
    const tabs = tab.parentNode.children;
    const tab_content = document.getElementById(tab.dataset.id);
    const tabContent = document.querySelectorAll('#' + tab_content.parentNode.id + ' .tab-content');

    for (let i = 0; i < tabs.length; i++) {
        tabs[i].classList.remove('tabs__tab--active');
        tabContent[i].classList.remove('tab-content--active');
    }

    tab.classList.add('tabs__tab--active');
    tab_content.classList.add('tab-content--active');
}

// mailchimp subscription
const newsletterForm = document.forms.newsletter;
const mailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

if (typeof newsletterForm !== 'undefined') {
    const newsletterFormInput = newsletterForm.elements[0];
    const newsletterFormSubmit = newsletterForm.elements[1];

    newsletterFormInput.addEventListener('input', controlForm);
    function controlForm () {
        newsletterFormSubmit.disabled = !mailPattern.test(newsletterFormInput.value);
    }

    newsletterForm.addEventListener('submit', (evt) => {
        evt.preventDefault();
        fetch(url + '/subscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: encodeURIComponent('email') + "=" + encodeURIComponent(newsletterFormInput.value)
        });
        newsletterFormInput.value = '';
    });
}

// contact us + request a meeting form
const contactForm = document.getElementById('contact');
const closeButton = document.querySelectorAll('.contact-form__close');
const noFocusArea = document.querySelectorAll('.no-focus');
const videoCTA = document.querySelectorAll('.videoCTA');
const video = document.getElementById('video');
const player = new Vimeo(video);

if (typeof videoCTA !== undefined && videoCTA !== null) {
    videoCTA.forEach(item => {
        item.addEventListener('click', (evt) => {
            evt.preventDefault();
            noFocusArea[0].classList.toggle('no-focus--hidden');
            player.play();
        });
    });
}

if (closeButton) {
    closeButton.forEach(item => {
        item.addEventListener('click', event => {
            event.preventDefault();
            noFocusArea.forEach(item => {
                item.classList.add('no-focus--hidden');
            });
            player.pause()
        })
    })
}

if (typeof contactForm !== undefined && contactForm !== null) {
    // meeting or video
    const meetingCTA = document.querySelectorAll('.cta-meeting');

    // contact us
    const contactInputs = contactForm.querySelectorAll('input, textarea, select');
    const contactLabels = contactForm.querySelectorAll('label');
    const contactSubmit = contactForm.querySelector('.contact-form__submit');
    let captcha = '';
    let meetingName = '';

    if (meetingCTA) {
        for (let i = 0; i < meetingCTA.length; i++) {
            meetingCTA[i].addEventListener('click', (evt) => {
                evt.preventDefault();
                meetingName = meetingCTA[i].dataset.event;
                noFocusArea[1].classList.toggle('no-focus--hidden');
            });
        }

    }

    contactInputs.forEach(function (input, index) {
        input.addEventListener('invalid', function (qualifiedName, value) {
            input.classList.add('contact-form__input--invalid');
        });

        input.addEventListener('input', function () {
            contactSubmit.classList.remove('contact-form__submit--success');
            contactSubmit.classList.remove('contact-form__submit--error');
            contactSubmit.setAttribute('value', 'SEND');

            if (input.value !== '') {
                contactLabels[index].classList.remove('contact-form__label--hidden');
            } else {
                contactLabels[index].classList.add('contact-form__label--hidden');
            }

            if (input.validity.valid) {
                input.classList.remove('contact-form__input--invalid');
                contactLabels[index].classList.remove('contact-form__label--invalid');
                contactLabels[index].textContent = contactLabels[index].title[0].toUpperCase() + contactLabels[index].title.substr(1);
            } else {
                input.classList.add('contact-form__input--invalid');
            }

            if (Array.apply(null, contactInputs).every(isValid) && captcha) {
                contactSubmit.removeAttribute('disabled');
            }
        });

        input.addEventListener("focusout", function () {
            if (!input.validity.valid) {
                contactLabels[index].classList.add('contact-form__label--invalid');
                contactLabels[index].textContent = 'Please enter a valid ' + contactLabels[index].title
            }
        })
    });

    contactForm.addEventListener('submit', (evt) => {
        evt.preventDefault();

        let formUrl = '';
        let body = {};
        let details = {};
        const supportUrl = url + '/support';
        const meetingUrl = url + '/meeting';
        const promocodeUrl = url + '/promocode';

        if (contactInputs[5].value === 'meeting') {
            formUrl = meetingUrl;
            details = {
                meeting_name: meetingName,
                meeting_message: contactInputs[4].value.trim(),
                meeting_user_email: contactInputs[2].value.trim(),
                meeting_full_name: contactInputs[0].value.trim(),
                meeting_job_title: contactInputs[3].value.trim(),
                meeting_company: contactInputs[1].value.trim(),
                captcha: captcha,
            };

        } else if (contactInputs[7].value === 'contact') {
            formUrl = supportUrl;
            details = {
                message: contactInputs[6].value.trim(),
                email: contactInputs[2].value.trim(),
                name: contactInputs[3].value.trim(),
                im: contactInputs[4].value.trim(),
                im_nickname: contactInputs[5].value.trim(),
                from: 'landing',
                captcha: captcha,
            }

        } else if (contactInputs[6].value === 'promocode') {
            formUrl = promocodeUrl;
            details = {
                name: contactInputs[0].value.trim(),
                surname: contactInputs[1].value.trim(),
                email: contactInputs[2].value.trim(),
                im: contactInputs[3].value.trim(),
                im_nickname: contactInputs[4].value.trim(),
                verticals: contactInputs[5].value.trim(),
                captcha: captcha,
            }
        }

        let formBody = [];
        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        body = formBody.join("&");

        fetch(formUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: body
        }).then(function(response) {
            contactInputs.forEach(function (input, idx, array) {
                if (idx !== array.length - 2){
                    input.value = ''
                }
            });
            contactSubmit.setAttribute('value', 'SUCCESSFULLY SENT!');
            contactSubmit.classList.remove('contact-form__submit--error');
            contactSubmit.classList.add('contact-form__submit--success');
        }).catch(function(error) {
            contactSubmit.setAttribute('value', 'ERROR');
            contactSubmit.classList.add('contact-form__submit--error');
        });
    });

    window.recaptchaWorks = function (params) {
        captcha = params;

        if (Array.apply(null, contactInputs).every(isValid) && recaptchaWorks) {
            contactSubmit.removeAttribute('disabled');
        }
    };

    window.recaptchaExpired = function (params) {
        contactSubmit.setAttribute('disabled', 'true');
    };

        function isValid(element) {
        return element.validity.valid;
    }
}

// cookie alert
const cookieAlert = document.querySelector('.cookie');

function acceptCookie() {
    if (typeof cookieAlert !== 'undefined' && cookieAlert !== null) {
        let allowCookie = localStorage.getItem('allowCookie');
        const cookieCTA = cookieAlert.querySelector('.cookie__cta');

        cookieCTA.addEventListener('click', function () {
            localStorage.setItem('allowCookie', true);
            cookieAlert.classList.add('cookie--accepted');
        });

        if (allowCookie) {
            cookieAlert.classList.add('cookie--accepted');
        } else {
            cookieAlert.classList.remove('cookie--accepted');
        }
    }
}

acceptCookie();

// CPC rates table
if ($("#svgMap").is(":visible")) {
    new svgMap({
        targetElementID: 'svgMap',
        data: svgMapStatPerCountryPush,
        colorMax: '#58E58D',
        colorMin: '#B2F7C3'
    });

}
let data = {
    push: svgMapStatPerCountryPush,
    onclick: svgMapStatPerCountryOnclick,
    ipp: svgMapStatPerCountryIPP
};

let buttons = $('.tabs-rates button')

if (buttons) {

    $.each(svgMapStatPerCountryPush.values, function (key, val) {
        $(".map-data").find("tbody").append('<tr><td>' + val.country + '</td><td>' + val.countryISO + '</td><td>' + val.impressions.toLocaleString("en-US") + '</td><td>' + val.clicks.toLocaleString("en-US") + '</td><td>' + val.avgCPC + '</td></tr>')
    });

    buttons.on('click', function () {

        let tab = $(this);

        buttons.removeClass('tabs-rates__tab--active');
        tab.addClass('tabs-rates__tab--active');

        if ($("#svgMap").is(":visible")) {
            $('#svgMap').html('');
            new svgMap({
                targetElementID: 'svgMap',
                data: data[tab.data('target')],
                colorMax: '#58E58D',
                colorMin: '#B2F7C3'
            });
        }

        $(".map-data tbody").html('');
        $('.map-data thead th:nth-child(4)').show();

            switch (tab.data('target')) {

                case 'push':
                    $('.map-data thead th:last-child span').html('CPC, $');
                    $.each(data[tab.data('target')].values, function (key, val) {
                        $(".map-data").find("tbody").append('<tr><td>' + val.country + '</td><td>' + val.countryISO + '</td><td>' + val.impressions.toLocaleString("en-US") + '</td><td>' + val.clicks.toLocaleString("en-US") + '</td><td>' + val.avgCPC + '</td></tr>')
                    });
                    break;

                case 'onclick':
                    $('.map-data thead th:last-child span').html('CPM, $');
                    $('.map-data thead th:nth-child(4)').hide();
                    $.each(data[tab.data('target')].values, function (key, val) {
                        $(".map-data").find("tbody").append('<tr><td>' + val.country + '</td><td>' + val.countryISO + '</td><td>' + val.impressions.toLocaleString("en-US") + '</td><td>' + val.avgCPM + '</td></tr>')
                    });
                    break;

                case 'ipp':
                    $('.map-data thead th:last-child span').html('CPC, $');
                    $.each(data[tab.data('target')].values, function (key, val) {
                        $(".map-data").find("tbody").append('<tr><td>' + val.country + '</td><td>' + val.countryISO + '</td><td>' + val.impressions.toLocaleString("en-US") + '</td><td>' + val.clicks.toLocaleString("en-US") + '</td><td>' + val.avgCPC + '</td></tr>')
                    });
                    break;


            }


    });
}



$(".map-data").find("th").click(function(){
    var table = $(this).parents('table').eq(0);
    var rows = table.find('tr:gt(0)').toArray().sort(comparer($(this).index()));
    this.asc = !this.asc;
    $('th div').remove();
    if (!this.asc) {
        rows = rows.reverse();
        $(this).append('<div class="arrow-down"></div>')
    } else {
        $(this).append('<div class="arrow-up"></div>')
    }
    for (var i = 0; i < rows.length; i++){table.append(rows[i])}
});

$(".map-data__search").find("select").click(function(){
    var table = $(this).parents('table').eq(0);
    var rows = table.find('tr:gt(0)').toArray().sort(comparer($(this).index()));
    this.asc = !this.asc;
    $('th div').remove();
    if (!this.asc) {
        rows = rows.reverse();
        $(this).append('<div class="arrow-down"></div>')
    } else {
        $(this).append('<div class="arrow-up"></div>')
    }
    for (var i = 0; i < rows.length; i++){table.append(rows[i])}
});

$('#select').on('change', function() {
    var table = $('.map-data');
    var rows = table.find('tr:gt(0)').toArray().sort(comparer($(this).val()));
    $('th div').remove();
    for (var i = 0; i < rows.length; i++){table.append(rows[i])}
});

function comparer(index) {
    return function(a, b) {
        var valA = getCellValue(a, index), valB = getCellValue(b, index);
        return $.isNumeric(parseFloat(valA.replace(/[^0-9-.]/g, ''))) && $.isNumeric(parseFloat(valB.replace(/[^0-9-.]/g, ''))) ? parseFloat(valA.replace(/[^0-9-.]/g, '')) - parseFloat(valB.replace(/[^0-9-.]/g, '')) : valA.toString().localeCompare(valB)
    }
}
function getCellValue(row, index){ return $(row).children('td').eq(index).text() }

$("#search, #search2").keyup(function() {
    var value = this.value.toLowerCase().trim();

    $(".map-data").find("tbody").find("tr").each(function(index) {
        var id = $(this).find("td:lt(2)").text().toLowerCase().trim();
        $(this).toggle(id.indexOf(value) !== -1);
    });

    if ($(".map-data").find("tbody").find("tr:visible").length < 1) {
        $(".map-data").find("tbody").append('<tr class="nothing-found"><td>Nothing found.</td><td></td><td></td><td></td><td></td></tr>')
    } else {
        $(".nothing-found").remove();
    }

});

// Preserve search params
const search = ((window || {}).location || {}).search;
if (search) {
    const links = document.querySelectorAll('a');
    let url = '';
    if (/pushok.me/.test(window.location.origin)) {
        url = 'pushok.me';
    } else if (/rollerads.com/.test(window.location.host)) {
        url = 'rollerads.com';
    } else {
        url = 'localhost';
    }

    links.forEach(a => {
        if (a.href.includes(url)) {
            a.href += search;
        }
    })
}